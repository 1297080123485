import { Reducer } from "redux";

import { sessionDataHandler } from "../sessionDataHandler";
import { Bot, BotConfig, ConversationLabelsUpdate } from "../types/bots";
import {
    ACTION_INCREMENT_STORE_RESPONSE_ATTRIBUTE_INDEX,
    ACTION_RESET_BOT_DATA,
    ACTION_SET_ACTIVE_BOT_STEP,
    ACTION_SET_ACTIVE_BOT_STEP_STATUS,
    ACTION_SET_BOT_CONFIG,
    ACTION_SET_IS_ESCALATED,
    ACTION_SET_IS_REDIRECTING,
    ACTION_SET_PENDING_FLOW,
    ACTION_SET_SHOW_MESSAGE_INPUT,
    ACTION_SET_STORE_RESPONSE,
    ACTION_START_BOT_SESSION,
    ACTION_START_SESSION,
    ACTION_UPDATE_CONVERSATION_LABELS,
    ACTION_UPDATE_TASK_ATTRIBUTES
} from "./actions/actionTypes";
import { BotState, BotStepStatus, WidgetConfig } from "./definitions";

const initialState: BotState = {
    status: BotStepStatus.Unhandled,
    storeResponseAttributeIndex: 4,
    isRedirecting: false
};

type BotReducerAction =
    | {
          type: typeof ACTION_START_BOT_SESSION;
          payload: {
              bot: Bot;
              activeBotStepId: number;
              widgetConfig: WidgetConfig;
          };
      }
    | {
          type: typeof ACTION_SET_ACTIVE_BOT_STEP_STATUS;
          payload: {
              status: BotStepStatus;
          };
      }
    | {
          type: typeof ACTION_SET_STORE_RESPONSE;
          payload: {
              storeResponse: boolean;
              storeResponseName: string;
          };
      }
    | {
          type: typeof ACTION_SET_SHOW_MESSAGE_INPUT;
          payload: {
              showMessageInput: boolean;
          };
      }
    | {
          type: typeof ACTION_SET_ACTIVE_BOT_STEP;
          payload: {
              botStepId: number;
          };
      }
    | {
          type: typeof ACTION_SET_IS_ESCALATED;
          payload: {
              isEscalated: boolean;
          };
      }
    | {
          type: typeof ACTION_UPDATE_TASK_ATTRIBUTES;
          payload: {
              conversationSid?: string;
              taskAttributes: Record<string, unknown>;
          };
      }
    | {
          type: typeof ACTION_UPDATE_CONVERSATION_LABELS;
          payload: {
              conversationSid?: string;
              conversationLabels: ConversationLabelsUpdate[];
          };
      }
    | {
          type: typeof ACTION_START_SESSION;
          payload: {
              botState: BotState;
          };
      }
    | {
          type: typeof ACTION_SET_BOT_CONFIG;
          payload: {
              botConfig: BotConfig;
          };
      }
    | {
          type: typeof ACTION_INCREMENT_STORE_RESPONSE_ATTRIBUTE_INDEX;
      }
    | {
          type: typeof ACTION_RESET_BOT_DATA;
      }
    | {
          type: typeof ACTION_SET_IS_REDIRECTING;
          payload: {
              isRedirecting: boolean;
          };
      }
    | {
          type: typeof ACTION_SET_PENDING_FLOW;
          payload: {
              pendingBotConfig: BotConfig;
              pendingConversationSid: string;
          };
      };

export const BotReducer: Reducer<BotState, BotReducerAction> = (
    state: BotState = initialState,
    action: BotReducerAction
): BotState => {
    let updatedState: BotState | undefined;
    switch (action.type) {
        case ACTION_START_SESSION: {
            updatedState = action.payload.botState ?? state;
            break;
        }
        case ACTION_START_BOT_SESSION: {
            updatedState = {
                ...state,
                bot: action.payload.bot,
                widgetConfig: action.payload.widgetConfig,
                activeBotStepId: action.payload.activeBotStepId
            };
            break;
        }
        case ACTION_SET_ACTIVE_BOT_STEP_STATUS: {
            updatedState = {
                ...state,
                status: action.payload.status
            };
            break;
        }
        case ACTION_SET_STORE_RESPONSE: {
            updatedState = {
                ...state,
                storeResponse: action.payload.storeResponse,
                storeResponseName: action.payload.storeResponseName
            };
            break;
        }
        case ACTION_SET_SHOW_MESSAGE_INPUT: {
            updatedState = {
                ...state,
                showMessageInput: action.payload.showMessageInput
            };
            break;
        }
        case ACTION_SET_ACTIVE_BOT_STEP: {
            updatedState = {
                ...state,
                activeBotStepId: action.payload.botStepId
            };
            break;
        }
        case ACTION_UPDATE_TASK_ATTRIBUTES: {
            if (state.isEscalated && action.payload.conversationSid) {
                sessionDataHandler.updateTaskAttributes(action.payload.conversationSid, action.payload.taskAttributes);
            }
            updatedState = {
                ...state,
                taskAttributes: { ...state.taskAttributes, ...action.payload.taskAttributes }
            };
            break;
        }
        case ACTION_SET_IS_ESCALATED: {
            updatedState = {
                ...state,
                isEscalated: action.payload.isEscalated
            };
            break;
        }
        case ACTION_UPDATE_CONVERSATION_LABELS: {
            if (state.isEscalated && action.payload.conversationSid && action.payload.conversationLabels) {
                // Update the conversation labels in a task if it has been created
                sessionDataHandler.updateConversationLabels(
                    action.payload.conversationSid,
                    action.payload.conversationLabels
                );
            }

            updatedState = state;
            break;
        }
        case ACTION_RESET_BOT_DATA: {
            updatedState = initialState;
            break;
        }
        case ACTION_SET_BOT_CONFIG: {
            updatedState = {
                ...state,
                bot: { ...state.bot, botConfig: action.payload.botConfig } as Bot
            };
            break;
        }
        case ACTION_INCREMENT_STORE_RESPONSE_ATTRIBUTE_INDEX: {
            if (state.storeResponseAttributeIndex && [4, 5, 6, 7].includes(state.storeResponseAttributeIndex)) {
                updatedState = {
                    ...state,
                    storeResponseAttributeIndex: (state.storeResponseAttributeIndex + 1) as 4 | 5 | 6 | 7 | 8
                };
            } else {
                updatedState = state;
            }
            break;
        }
        case ACTION_SET_IS_REDIRECTING: {
            updatedState = {
                ...state,
                isRedirecting: action.payload.isRedirecting
            };
            break;
        }
        case ACTION_SET_PENDING_FLOW: {
            updatedState = {
                ...state,
                pendingBotConfig: action.payload.pendingBotConfig,
                pendingConversationSid: action.payload.pendingConversationSid
            };
            break;
        }
        default:
            return state;
    }

    sessionDataHandler.storeBotState(updatedState);
    return updatedState;
};

export type BotReducer = typeof BotReducer;
