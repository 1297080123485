import { z } from "zod";

import { BotStepType } from "../BotStepType";

const conversationLabelsUpdate = z.object({
    method: z.literal("add").or(z.literal("remove")),
    label: z.string()
});

export const updateConversationLabelsBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.UpdateConversationLabels),
    waitInSeconds: z.number().optional(),
    updateConversationLabels: z.array(conversationLabelsUpdate),
    nextStepId: z.number().optional()
});

export type ConversationLabelsUpdate = z.infer<typeof conversationLabelsUpdate>;
export type UpdateConversationLabelsBotStep = z.infer<typeof updateConversationLabelsBotStepSchema>;
