import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const formStyles: BoxStyleProps = {
    padding: "space40",
    overflow: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
};

export const titleStyles: TextStyleProps = {
    fontSize: "fontSize70",
    marginTop: "space50",
    marginBottom: "space40"
};

export const introStyles: TextStyleProps = {
    marginBottom: "space50"
};

export const fieldStyles: BoxStyleProps = {
    marginBottom: "space40"
};
