import { z } from "zod";

import { BotStepType } from "../BotStepType";

const profileUpdate = z.object({
    method: z.literal("remove").or(z.literal("clear")).or(z.literal("set")).or(z.literal("add")),
    value: z.string(),
    field: z.string()
});

export const updateProfileBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.UpdateProfile),
    waitInSeconds: z.number().optional(),
    profileUpdates: z.array(profileUpdate),
    nextStepId: z.number()
});

/*
 * export type ProfileUpdate = z.infer<typeof profileUpdate>;
 * export type UpdateProfileStep = z.infer<typeof updateProfileBotStepSchema>;
 */
