import { AnyAction } from "redux";

import type { Conversation } from "@twilio/conversations";
import { ThunkDispatch } from "redux-thunk";
import { sessionDataHandler } from "../../sessionDataHandler";
import { ChatReducer } from "../chat.reducer";
import { EngagementPhase } from "../definitions";
import { SessionReducer } from "../session.reducer";
import {
    changeEngagementPhase,
    changeExpandedStatus,
    resetBotData,
    resetChatState,
    updatePreEngagementData
} from "./genericActions";

export function endSession(conversation?: Conversation, close?: boolean) {
    return async (dispatch: ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>) => {
        // Clearing state data
        dispatch(updatePreEngagementData({ name: "", email: "", phone: "", query: "" }));
        if (close) {
            dispatch(changeExpandedStatus({ expanded: false }));
        }


        dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        dispatch(resetBotData());
        dispatch(resetChatState());

        // Ending the conversation if one has been created
        if (conversation) {
            await sessionDataHandler.endChat(conversation.sid);
        }

        // Clearing session data
        sessionDataHandler.clear();
    };
}
