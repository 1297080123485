import { z } from "zod";

import { BotStepType } from "../BotStepType";

export const addToIVRPathBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.AddToIVRPath),
    ivrPath: z.string(),
    waitInSeconds: z.number().optional(),
    nextStepId: z.number().optional()
});

export type AddToIVRPathBotStep = z.infer<typeof addToIVRPathBotStepSchema>;
