import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";
import { JSONObject } from "@twilio/conversations";
import { Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import {
    clearPending,
    setActiveBotStep,
    setActiveBotStepStatus,
    setBotConfig,
    setIsEscalated,
    setIsRedirecting
} from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { ChatReducer } from "../store/chat.reducer";
import { AppState, BotStepStatus, PreEngagementData } from "../store/definitions";
import { SessionReducer } from "../store/session.reducer";
import { containerStyles, redirectingStyles, textStyles } from "./styles/Redirecting.styles";
import type { Transcript } from "./Transcript";

export const redirectingSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        messages: state.chat.messages,
        users: state.chat.users,
        preEngagementData: (state.chat.conversation?.attributes as JSONObject)
            ?.pre_engagement_data as PreEngagementData,
        transcriptConfig: state.config.transcript,
        showTranscriptButton: state.bot.widgetConfig?.showTranscriptButton ?? false,
        conversationClient: state.chat.conversationsClient,
        conversation: state.chat.conversation,
        token: state.session.token ?? "",
        pendingBotConfig: state.bot.pendingBotConfig,
        pendingConversationSid: state.bot.pendingConversationSid ?? ""
    })
);

export const Redirecting = () => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const {
        messages,
        users,
        preEngagementData,
        transcriptConfig,
        showTranscriptButton,
        conversationClient,
        conversation,
        token,
        pendingBotConfig,
        pendingConversationSid
    } = useSelector(redirectingSelector);

    const handleContinueRedirectFlow = useCallback(async () => {
        let existingConversation = undefined;
        if (conversation?.sid) {
            existingConversation = await conversationClient?.peekConversationBySid(conversation.sid);
        }

        // set isEscalated to false
        dispatch(setIsEscalated(false));

        if (existingConversation?.state?.current && existingConversation?.state?.current !== "active") {
            // join the new conversation if the existing one has been ended (init actions modified?)
            console.log("loading conversation into session: ", pendingConversationSid);
            dispatch(
                initSession({
                    token,
                    conversationSid: pendingConversationSid,
                    conversationsClient: conversationClient
                })
            );
        }

        // update the bot steps
        if (pendingBotConfig) {
            dispatch(setBotConfig(pendingBotConfig));
        }
        dispatch(setActiveBotStep(pendingBotConfig?.initialStepId ?? 1));
        dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
        dispatch(setIsRedirecting(false));
        dispatch(clearPending());
    }, [conversation, conversationClient, dispatch, pendingBotConfig, pendingConversationSid, token]);

    let TranscriptComponent: typeof Transcript | undefined = undefined;

    // This file and its related dependencies are only bundled if transcripts are enabled in .env file
    if (
        process.env.REACT_APP_DOWNLOAD_TRANSCRIPT_ENABLED === "true" ||
        process.env.EMAIL_TRANSCRIPT_ENABLED === "true"
    ) {
        ({ Transcript: TranscriptComponent } = require("./Transcript"));
    }

    return (
        <Box {...containerStyles}>
            <Text as="h3" {...redirectingStyles}>
                Redirecting you to the next stage...
            </Text>
            {TranscriptComponent && showTranscriptButton ? (
                <TranscriptComponent
                    messages={messages}
                    preEngagementData={preEngagementData}
                    users={users}
                    transcriptConfig={transcriptConfig}
                />
            ) : (
                <Fragment />
            )}
            <Text as="p" {...textStyles}>
                When you are ready, continue to the next stage.
            </Text>
            <Button variant="primary" data-test="start-new-chat-button" onClick={handleContinueRedirectFlow}>
                Continue
            </Button>
        </Box>
    );
};
