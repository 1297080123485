import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";

import { createSelector } from "reselect";
import { AppState } from "../store/definitions";
import { NotificationBarItem } from "./NotificationBarItem";
import { notificationBarContainerStyles, notificationBarStyles } from "./styles/NotificationBar.styles";

const notificationBarSelector = createSelector(
    (state: AppState) => state,
    (state) => state.notifications
);

export const NotificationBar = () => {
    const notifications = useSelector(notificationBarSelector);

    return (
        <Box {...notificationBarContainerStyles}>
            <Box {...notificationBarStyles}>
                {notifications.map((notification) => (
                    <NotificationBarItem key={notification.id} {...notification} />
                ))}
            </Box>
        </Box>
    );
};
