import { z } from "zod";

import { BotStepType } from "../BotStepType";
import { callToActionOptionSchema } from "./FreeTextResponseBotStep";
import { stepContentSchema } from "./MultipleChoiceBotStep";

export const plainTextNoActionBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.NoAction),
    subType: z.literal("plainText"),
    message: z.string(),
    /**
     * If specified, then this will continue onto the step specified, else the bot config will end here.
     */
    nextStepId: z.number().optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const mediaNoActionBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.NoAction),
    subType: z.literal("media"),
    media: z.array(z.string()).min(1),
    /**
     * If specified, then this will continue onto the step specified, else the bot config will end here.
     */
    nextStepId: z.number().optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const callToActionNoActionBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.NoAction),
    subType: z.literal("callToAction"),
    message: z.string(),
    callsToAction: z.array(callToActionOptionSchema).min(1),
    /**
     * If specified, then this will continue onto the step specified, else the bot config will end here.
     */
    nextStepId: z.number().optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const cardNoActionBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.NoAction),
    subType: z.literal("card"),
    content: z.array(stepContentSchema).min(1),
    ctaOptions: z.array(callToActionOptionSchema).min(1).optional(),
    nextStepId: z.number().optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const noActionBotStepSchema = mediaNoActionBotStepSchema
    .or(plainTextNoActionBotStepSchema)
    .or(callToActionNoActionBotStepSchema)
    .or(cardNoActionBotStepSchema);

export type NoActionBotStep = z.infer<typeof noActionBotStepSchema>;
