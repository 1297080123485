import { z } from "zod";
import { BotStepType } from "../BotStepType";

const nextStepOption = z.object({
    operator: z.string(),
    nextStepId: z.number().optional()
});

export const checkLoggedInAgentsSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.CheckLoggedInAgents),
    waitInSeconds: z.number().optional(),
    skills: z.array(z.string()),
    activities: z.array(z.string()),
    operand: z.enum(["total", "fixedValue", "percentage"]),
    value: z.number().optional(),
    nextStepOptions: z.array(nextStepOption).optional()
});

export type CheckLoggedInAgentsNextStepOption = z.infer<typeof nextStepOption>;
export type CheckLoggedInAgentsBotStep = z.infer<typeof checkLoggedInAgentsSchema>;
