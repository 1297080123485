import { z } from "zod";

import { BotStepType } from "../BotStepType";

const stepSimpleOptionSchema = z.object({
    optionType: z.literal("simple"),
    label: z.string(),
    nextStepId: z.number().optional(),
    description: z.string().optional(),
    profileFieldName: z.string().optional(),
    previousNodeId: z.number().optional()
});

const nextStepVariantSchema = z.object({
    nextStepId: z.number(),
    previousNodeId: z.number().or(z.undefined())
});

const contextualStepOptionSchema = z.object({
    optionType: z.literal("contextual"),
    label: z.string(),
    description: z.string().optional(),
    profileFieldName: z.string().optional(),
    nextStepVariants: z.array(nextStepVariantSchema)
});

const stepOptionSchema = contextualStepOptionSchema.or(stepSimpleOptionSchema);

const stringStepContentSchema = z.object({
    type: z.literal("string"),
    message: z.string()
});

const seperatedStringStepContentSchema = z.object({
    type: z.literal("seperated-string"),
    header: z.string().optional(),
    body: z.string(),
    footer: z.string().optional(),
    media: z.array(z.string()).optional()
});

const videoStepContentSchema = z.object({
    type: z.literal("video"),
    videoUrl: z.string()
});

export type StringStepContent = z.infer<typeof stringStepContentSchema>;
export type SeperatedStringStepContent = z.infer<typeof seperatedStringStepContentSchema>;
export type VideoStepContent = z.infer<typeof videoStepContentSchema>;

export const stepContentSchema = stringStepContentSchema
    .or(seperatedStringStepContentSchema)
    .or(videoStepContentSchema);

export type StepContent = z.infer<typeof stepContentSchema>;

export const multipleChoiceStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.MultipleChoice),
    content: z.array(stepContentSchema).min(1),
    options: z.array(stepOptionSchema).min(1),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export type MultipleChoiceBotStep = z.infer<typeof multipleChoiceStepSchema>;

export type StepOption = z.infer<typeof stepOptionSchema>;

export type NextStepVariant = z.infer<typeof nextStepVariantSchema>;
