/* eslint-disable no-case-declarations */
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import { DEFAULT_BOT_MESSAGE_DELAY_SECONDS } from "../../constants";
import { useCanHandleBotStep } from "../../hooks/useCanHandleBotSteps";
import {
    changeEngagementPhase,
    sendConversationMessage,
    setActiveBotStep,
    setActiveBotStepStatus,
    setShowMessageInput,
    setStoreResponse
} from "../../store/actions/genericActions";
import { ChatReducer } from "../../store/chat.reducer";
import { AppState, BotStepStatus, EngagementPhase } from "../../store/definitions";
import { SessionReducer } from "../../store/session.reducer";
import { BotStepType, CallToActionOption, FreeTextResponseBotStep } from "../../types/bots";
import { getNextStepFromMessage } from "../../utils/getNextStepFromMessage";
import { MessageInput } from "../MessageInput";

interface FreeTextResponseStepProps {
    botStep: FreeTextResponseBotStep;
}

const freeTextResponseStepSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversation: state.chat.conversation,
        activeBotStepStatus: state.bot.status,
        isEscalated: state.bot.isEscalated,
        storeResponse: state.bot.storeResponse ?? false,
        storeResponseName: state.bot.storeResponseName,
        storeResponseAttributeIndex: state.bot.storeResponseAttributeIndex,
        conversationAttributes: state.bot.taskAttributes?.conversations as Record<string, unknown> | undefined
    })
);

export const FreeTextResponseStep: FC<FreeTextResponseStepProps> = ({ botStep }) => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { canHandleBotStep } = useCanHandleBotStep(botStep, {
        requiredStepType: BotStepType.FreeTextResponse
    });
    const {
        conversation,
        activeBotStepStatus,
        isEscalated,
        storeResponse,
        storeResponseAttributeIndex,
        storeResponseName,
        conversationAttributes
    } = useSelector(freeTextResponseStepSelector);

    const sendBotMessage = useCallback(
        async ({
            message,
            contentSid,
            cta,
            header,
            footer,
            attachedFiles
        }: {
            message: string;
            contentSid: string;
            cta?: CallToActionOption[];
            header?: string;
            footer?: string;
            attachedFiles?: File[];
        }) => {
            dispatch(
                sendConversationMessage({
                    sentByBot: true,
                    conversation,
                    body: message,
                    header,
                    footer,
                    cta,
                    variables: botStep.variables,
                    contentSid,
                    attachedFiles,
                    storeResponse,
                    storeResponseAttributeIndex,
                    storeResponseName,
                    conversationAttributes
                })
            );
        },
        [sendConversationMessage, storeResponse, storeResponseAttributeIndex, storeResponseName, conversationAttributes]
    );

    useEffect(() => {
        const handleBotStep = async () => {
            if (!canHandleBotStep) {
                return;
            }

            // Mark step as being processed, and hide message input
            dispatch(setActiveBotStepStatus(BotStepStatus.Processing));
            dispatch(setShowMessageInput(false));
            if (botStep.storeResponseName) {
                dispatch(setStoreResponse(true, botStep.storeResponseName));
            }

            // adds some delay to the bot step
            const waitInMs = (botStep.waitInSeconds ?? DEFAULT_BOT_MESSAGE_DELAY_SECONDS) * 1000;
            await new Promise<void>((resolve) => setTimeout(resolve, waitInMs));

            // Display message from the bot that's configured for this step
            switch (botStep.subType) {
                case "media":
                    const fileFetchPromises: Promise<File>[] = botStep.media.map(async (mediaUri, index) => {
                        const data = await fetch(mediaUri, {
                            method: "GET"
                        });
                        const blob = await data.blob();
                        return new File([blob], `attachment_${index + 1}`, {
                            type: blob.type
                        });
                    });

                    const attachedFiles = await Promise.all(fileFetchPromises);
                    sendBotMessage({
                        message: "",
                        contentSid: botStep.contentSid,
                        attachedFiles
                    });
                    break;
                case "plainText":
                    sendBotMessage({ message: botStep.message, contentSid: botStep.contentSid });
                    break;
                case "callToAction":
                    sendBotMessage({
                        message: botStep.message,
                        contentSid: botStep.contentSid,
                        cta: botStep.callsToAction
                    });
                    break;
                case "card":
                    for (const contentItem of botStep.content) {
                        switch (contentItem.type) {
                            case "string":
                                sendBotMessage({
                                    message: contentItem.message,
                                    contentSid: botStep.contentSid,
                                    cta: botStep.ctaOptions
                                });
                                break;

                            case "seperated-string":
                                sendBotMessage({
                                    message: contentItem.body,
                                    contentSid: botStep.contentSid,
                                    cta: botStep.ctaOptions,
                                    header: contentItem.header,
                                    footer: contentItem.footer
                                });
                                break;

                            case "video":
                                sendBotMessage({
                                    message: contentItem.videoUrl,
                                    contentSid: botStep.contentSid,
                                    cta: botStep.ctaOptions
                                });
                                break;

                            default:
                                console.error("[NoActionStep] Unknown content type", contentItem);
                        }
                    }
                    break;
                default:
                    throw new Error(
                        `Free text response subtype not recognised: ${(botStep as { subType: string }).subType}`
                    );
            }

            /*
             * Now we need to wait for the user to send a message using the normal message input.
             * Rather than enabling the message input at an app state level, we instead render the
             * component within this step component so we can more easily listen to the on-send event
             * and react to it.
             *
             * The message input should be enabled, but we should disallow media uploads at this point.
             */

            dispatch(setActiveBotStepStatus(BotStepStatus.WaitingForUserInput));
        };
        handleBotStep();
    }, [dispatch, sendBotMessage, canHandleBotStep, botStep]);
    return (
        <>
            {activeBotStepStatus === BotStepStatus.WaitingForUserInput && (
                <MessageInput
                    disableMediaSending
                    onSendMessage={(message: string) => {
                        // Go back to "processing" so we don't capture any more input
                        dispatch(setActiveBotStepStatus(BotStepStatus.Processing));

                        const nextBotStepId = getNextStepFromMessage(botStep, message);

                        // Route to next bot step based on correct option
                        if (nextBotStepId) {
                            dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                            dispatch(setActiveBotStep(nextBotStepId));
                        } else if (isEscalated) {
                            dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
                        }
                    }}
                />
            )}
        </>
    );
};
