import { z } from "zod";

import { botStepsSchema } from "./BotSteps";

function initialStepExists({ steps, initialStepId }: z.infer<typeof botConfig>): boolean {
    // Check that the initial step specified actually exists

    if (initialStepId === null) {
        // Should remove later, but this avoids this being a breaking change for existing configs
        return true;
    }

    const initialStep = steps.find((step) => step.stepId === initialStepId);

    return initialStep !== null;
}

const botConfig = z.object({
    initialStepId: z.number().optional(),
    steps: botStepsSchema
});

export const botConfigSchema = botConfig.refine(initialStepExists, {
    message: "The initial step ID provided does not exist in any of the configured steps"
});

export type BotConfig = z.infer<typeof botConfigSchema>;
