import { sessionDataHandler } from "../sessionDataHandler";

/**
 * Evaluates the values at runtime of variables.
 * @param variables a record containing variables to evaluate, e.g. {"1": "queue-position", "2": "average-wait-time"}
 * @returns evaluated variables Record<string, string>
 */
export const evaluateVariables = async (variables: Record<string, string>, conversationSid?: string) => {
    const keys: string[] = Object.keys(variables);
    const newVars: Record<string, string> = {};

    console.log("evaluateVariables: ", variables);

    for (const key of keys) {
        switch (variables[key]) {
            case "queue-position":
                if (!conversationSid) break;
                newVars[key] = await sessionDataHandler.getTaskData({ type: "queue-position", conversationSid });
                console.log("fetched queue-position: ", newVars[key]);
                break;
            case "average-wait-time":
                if (!conversationSid) break;
                newVars[key] = await sessionDataHandler.getTaskData({ type: "average-wait-time", conversationSid });
                console.log("fetched average-wait-time: ", newVars[key]);
                break;
            default:
                newVars[key] = variables[key];
                break;
        }
    }

    return newVars;
};
