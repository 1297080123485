import { z } from "zod";
import { BotStepType } from "../BotStepType";

const nextStepOption = z.object({
    operator: z.string(),
    nextStepId: z.number().optional()
});

export const checkQueueCapacitySchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.CheckQueueCapacity),
    waitInSeconds: z.number().optional(),
    skill: z.string(),
    capacityMax: z.number(),
    nextStepOptions: z.array(nextStepOption).optional()
});

export type CheckQueueCapacityNextStepOption = z.infer<typeof nextStepOption>;
export type CheckQueueCapacityBotStep = z.infer<typeof checkQueueCapacitySchema>;
