import { z } from "zod";

export enum BotStepType {
    /**
     * Outputs a message from the bot and then displays 1..many buttons
     * the user can select. On pressing one of the buttons, a message will
     * be sent from the user using the button label, and we'll route to the
     * step configured for that option.
     */
    MultipleChoice = "multiple-choice",

    /**
     * Outputs a message from the bot and displays a number of inline form
     * fields. When submitted, the aggregate form values will be
     * sent as a single message, and then we'll route to the next step.
     */
    Form = "form",

    /**
     * Outputs a message from the bot, and allows the user to
     * respond using the message input field. Will continue to
     * the next step once a user has sent a message.
     */
    FreeTextResponse = "free-text-response",

    /**
     * Outputs a message from the bot, and then routes to the
     * queue specified in the bot step config.
     *
     * Leaf node and routes to no further steps.
     *
     * Message input will be enabled, as we'll be routed into
     * a queue to speak with an agent.
     */
    RouteToQueue = "route-to-queue",

    /**
     * Outputs a message from the bot, which will include attributes
     * that allows the API to create a task in the appropriate channel
     * and then end the chat.
     *
     * Example use cases: Out of hours, call-backs based on previously entered data.
     *
     * Leaf node and routes to no further steps.
     *
     * The message input will be disabled for this step, and the API will
     * end the conversation soon after.
     */
    CreateTask = "create-task",

    /**
     * Optionally outputs a bot message, but otherwise performs no action
     *
     * Leaf node and routes to no further steps.
     *
     * The message input will be disabled for this step and no further
     * interaction is possible.
     */
    NoAction = "no-action",

    // Updates the azure profile, only for use with clients using the azure database.
    UpdateProfile = "update-profile",

    // Updates the azure conversatoin labels, only for use with clients using the azure database.
    UpdateConversationLabels = "update-conversation-labels",

    // Updates the task attributes of the related task if the conversation has been escalated by a RouteToQueue or CreateTask node
    UpdateTaskAttributes = "update-task-attributes",

    // Updates the ivr_path task attribute
    AddToIVRPath = "add-to-ivr-path",

    // Checks the logged in agents
    CheckLoggedInAgents = "check-logged-in-agents",

    // Checks the queue capacity
    CheckQueueCapacity = "check-queue-capacity"
}

export const botStepTypeSchema = z.nativeEnum(BotStepType);
