import { z } from "zod";

import { addToIVRPathBotStepSchema } from "./BotSteps/AddToIVRPathBotStep";
import { checkLoggedInAgentsSchema } from "./BotSteps/CheckLoggedInAgentsStep";
import { checkQueueCapacitySchema } from "./BotSteps/CheckQueueCapacityStep";
import { createTaskBotStepSchema } from "./BotSteps/CreateTaskBotStep";
import { freeTextResponseBotStepSchema } from "./BotSteps/FreeTextResponseBotStep";
import { inlineFormBotStepSchema } from "./BotSteps/InlineFormBotStep";
import { multipleChoiceStepSchema } from "./BotSteps/MultipleChoiceBotStep";
import { noActionBotStepSchema } from "./BotSteps/NoActionBotStep";
import { routeToQueueBotStepSchema } from "./BotSteps/RouteToQueueBotStep";
import { updateConversationLabelsBotStepSchema } from "./BotSteps/UpdateConversationLablesStep";
import { updateProfileBotStepSchema } from "./BotSteps/UpdateProfileStep";
import { updateTaskAttributesStepSchema } from "./BotSteps/UpdateTaskAttributesStep";

export const botStepSchema = multipleChoiceStepSchema
    .or(inlineFormBotStepSchema)
    .or(freeTextResponseBotStepSchema)
    .or(routeToQueueBotStepSchema)
    .or(createTaskBotStepSchema)
    .or(noActionBotStepSchema)
    .or(updateProfileBotStepSchema)
    .or(updateConversationLabelsBotStepSchema)
    .or(updateTaskAttributesStepSchema)
    .or(addToIVRPathBotStepSchema)
    .or(checkLoggedInAgentsSchema)
    .or(checkQueueCapacitySchema);

export type BotStep = z.infer<typeof botStepSchema>;
