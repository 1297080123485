import merge from "lodash.merge";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { WebchatWidget } from "./components/WebchatWidget";
import { initLogger } from "./logger";
import { sessionDataHandler } from "./sessionDataHandler";
import { updatePreEngagementData } from "./store/actions/genericActions";
import { initConfig } from "./store/actions/initActions";
import { ConfigState, PreEngagementData } from "./store/definitions";
import { store } from "./store/store";

const defaultConfig: ConfigState = {
    serverUrl: "http://localhost:3001",
    theme: {
        isLight: true
    },
    fileAttachment: {
        enabled: true,
        maxFileSize: 16777216, // 16 MB
        acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"]
    },
    transcript: {
        downloadEnabled: false,
        emailEnabled: false,
        emailSubject: (agentNames) => {
            let subject = "Transcript of your chat";
            if (agentNames.length > 0) {
                subject = subject.concat(` with ${agentNames[0]}`);
                agentNames.slice(1).forEach((name) => (subject = subject.concat(` and ${name}`)));
            }
            return subject;
        },
        emailContent: (customerName, transcript) => {
            return `<div><h1 style="text-align:center;">Chat Transcript</h1><p>Hello ${customerName},<br><br>Please see below your transcript, with any associated files attached, as requested.<br><br>${transcript}</p></div>`;
        }
    }
};

const initWebchat = async (config: ConfigState) => {
    const mergedConfig = merge({}, defaultConfig, config);
    sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
    store.dispatch(initConfig(mergedConfig));
    initLogger();
    const rootElement = document.getElementById("twilio-webchat-widget-root");

    if (rootElement) {
        const root = createRoot(rootElement);
        root.render(
            <Provider store={store}>
                <WebchatWidget />
            </Provider>
        );
    }

    if (window.Cypress) {
        window.store = store;
    }
};

// Create the global function for adding additional form data
const setFormData = (data: Partial<PreEngagementData>) => {
    store.dispatch(updatePreEngagementData(data));
};

declare global {
    interface Window {
        Twilio: {
            setFormData: (data: Partial<PreEngagementData>) => void;
        };
        Cypress: Cypress.Cypress;
        store: typeof store;
    }
}

// Expose `initWebchat` and `addAdditionalFormData` functions to window object
Object.assign(window, {
    Twilio: {
        setFormData
    }
});

// Initialize the webchat when the DOM is loaded
window.addEventListener("DOMContentLoaded", () => {
    // Create the root div if it doesn't exist
    if (!document.getElementById("twilio-webchat-widget-root")) {
        const rootDiv = document.createElement("div");
        rootDiv.id = "twilio-webchat-widget-root";
        document.body.appendChild(rootDiv);
    }

    initWebchat({
        serverUrl: process.env.REACT_APP_SERVER_URL || "http://localhost:3001",
        theme: {
            isLight: true,
            overrides: {
                backgroundColors: {
                    colorBackgroundPrimary: "#221551",
                    colorBackgroundPrimaryStrong: "#A51890",
                    colorBackgroundPrimaryStronger: "#671E75"
                },
                borderColors: {
                    colorBorderPrimary: "#221551",
                    colorBorderPrimaryStrong: "#A51890",
                    colorBorderPrimaryStronger: "#671E75"
                },
                shadows: {
                    shadowBorderPrimary: "0 0 0 1px #221551",
                    shadowBorderPrimaryStrong: "0 0 0 1px #A51890",
                    shadowBorderPrimaryStronger: "0 0 0 1px #671E75"
                },
                textColors: {
                    colorTextPrimary: "#221551",
                    colorTextPrimaryStrong: "#A51890",
                    colorTextPrimaryStronger: "#671E75"

                }
            }
        }
    });
});
