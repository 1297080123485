import { BotStep, BotStepType } from "../types/bots";

 
export const getNextStepFromMessage = (botStep: BotStep, message: string): number | undefined => {
    if (botStep.type === BotStepType.FreeTextResponse) {
        let { options } = botStep;
        if (!options) {
            // use the nextStepId if it exists on the object
            return (botStep as { nextStepId: number | undefined }).nextStepId;
        }

        options = options.filter((option) => {
            if (option.type === "default") return true;

            switch (option.operator) {
                case "eq":
                    return message === option.value;
                case "neq":
                    return message !== option.value;
                case "contains":
                    return message.includes(option.value);
                case "ncontains":
                    return !message.includes(option.value);
                case "startswith":
                    return message.startsWith(option.value);
                default:
                    return false;
            }
        });

        /*
         * If a node was found remove the default option
         * This assumes that there are not multiple default options
         */
        if (options.length > 1) {
            options = options.filter((option) => option.type !== "default");
        }

        if (options.length > 0) return options[0].nextStepId;
    }
};
