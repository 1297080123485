import { GenericThemeShape } from "@twilio-paste/theme";
import { Client, Conversation, Message, Participant, User } from "@twilio/conversations";

import { FileAttachmentConfig, TranscriptConfig } from "../definitions";
import { Bot, BotConfig } from "../types/bots";

type AlertVariants = "error" | "neutral" | "warning";

export enum EngagementPhase {
    PreEngagementForm = "PreEngagementForm",
    MessagingCanvas = "MessagingCanvas",
    BotCanvas = "BotCanvas",
    Loading = "Loading"
}

export type ChatState = {
    conversationsClient?: Client;
    conversation?: Conversation;
    participants?: Participant[];
    users?: User[];
    messages?: Message[];
    attachedFiles?: File[];
    conversationState?: string;
};

export type BotState = {
    bot?: Bot;
    activeBotStepId?: number;
    status?: BotStepStatus;
    storeResponse?: boolean;
    storeResponseName?: string;
    storeResponseAttributeIndex?: 4 | 5 | 6 | 7 | 8; //These are the unused conversation attributes;
    showMessageInput?: boolean;
    isEscalated?: boolean;
    taskAttributes?: Record<string, unknown>;
    widgetConfig?: WidgetConfig;
    isRedirecting?: boolean;
    pendingBotConfig?: BotConfig;
    pendingConversationSid?: string;
};

export type WidgetConfig = {
    mainHeader?: string;
    subHeader?: string;
    subHeaderType?: "dynamic" | "static";
    subHeaderScheduleId?: string;
    allowSendMedia?: boolean;
    showTranscriptButton?: boolean;
    miniFooter?: string;
    fullFooter?: string;
};

export type SessionState = {
    currentPhase: EngagementPhase;
    expanded: boolean;
    token?: string;
    conversationSid?: string;
    conversationsClient?: Client;
    conversation?: Conversation;
    users?: User[];
    participants?: Participant[];
    messages?: Message[];
    conversationState?: "active" | "inactive" | "closed";
    preEngagementData?: PreEngagementData;
};

export type ConfigState = {
    serverUrl?: string;
    theme?: {
        isLight?: boolean;
        overrides?: Partial<GenericThemeShape>;
    };
    fileAttachment?: FileAttachmentConfig;
    transcript?: TranscriptConfig;
};

export type Notification = {
    dismissible: boolean;
    id: string;
    onDismiss?: () => void;
    message: string;
    timeout?: number;
    type: AlertVariants;
};

export enum BotStepStatus {
    Unhandled,
    Processing,
    WaitingForUserInput
}

export type NotificationState = Notification[];

export type AppState = {
    chat: ChatState;
    config: ConfigState;
    session: SessionState;
    notifications: NotificationState;
    bot: BotState;
};


// Define the base structure for pre-engagement data
export interface BasePreEngagementData {
    name: string;
    email: string;
    query: string;
    phone: string;
}

// Extend the base interface to allow for additional string properties
export interface PreEngagementData extends BasePreEngagementData {
    [key: string]: string;
}

// Type for partial pre-engagement data, useful for updates
export type PartialPreEngagementData = Partial<PreEngagementData>;
