import { Box } from "@twilio-paste/core/box";
import { AnimatePresence } from "framer-motion";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState, EngagementPhase } from "../store/definitions";
import { EntryPoint } from "./EntryPoint";
import { LoadingPhase } from "./LoadingPhase";
import { BotCanvasPhase } from "./phases/BotCanvasPhase";
import { MessagingCanvasPhase } from "./phases/MessagingCanvasPhase";
import { PreEngagementFormPhase } from "./phases/PreEngagementFormPhase";
import { MotionInnerContainer, MotionOuterContainer } from "./styles/RootContainer.styles";

const duration: number = 0.2;

const containerVariants = {
    hidden: {
        opacity: 0,
        y: 20,
        transition: { duration: duration, ease: "easeInOut" }
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: duration, ease: "easeInOut" }
    },
    exit: {
        opacity: 0,
        y: 20,
        transition: { duration: duration, ease: "easeInOut" }
    }
};

const innerContainerVariants = {
    hidden: {
        opacity: 0,
        scale: 0.95,
        transition: { duration: duration, ease: "easeInOut" }
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { duration: duration, ease: "easeInOut" }
    },
    exit: {
        opacity: 0,
        scale: 0.95,
        transition: { duration: duration, ease: "easeInOut" }
    }
};

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.BotCanvas:
            return <BotCanvasPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        case EngagementPhase.PreEngagementForm:
        default:
            return <PreEngagementFormPhase />;
    }
};

const rootContainerSelector = createSelector(
    (state: AppState) => state.session,
    (session) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    })
);

export function RootContainer() {
    const { currentPhase, expanded } = useSelector(rootContainerSelector);

    const getContainerHeight = () => {
        if (isMobile || currentPhase === EngagementPhase.PreEngagementForm) {
            return "100%";
        }
        return "550px";
    };

    return (
        <Box>
            <MotionOuterContainer variants={containerVariants} initial="hidden" animate="visible" exit="exit">
                <AnimatePresence>
                    {expanded && (
                        <MotionInnerContainer
                            data-test="root-container"
                            variants={innerContainerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{ height: getContainerHeight() }}
                        >
                            {getPhaseComponent(currentPhase)}
                        </MotionInnerContainer>
                    )}
                </AnimatePresence>
                <EntryPoint />
            </MotionOuterContainer>
        </Box>
    );
}
