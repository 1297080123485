import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import { DEFAULT_BOT_MESSAGE_DELAY_SECONDS } from "../../constants";
import { useCanHandleBotStep } from "../../hooks/useCanHandleBotSteps";
import { sessionDataHandler } from "../../sessionDataHandler";
import {
    changeEngagementPhase,
    sendConversationMessage,
    setActiveBotStep,
    setActiveBotStepStatus,
    setIsEscalated,
    setShowMessageInput
} from "../../store/actions/genericActions";
import { ChatReducer } from "../../store/chat.reducer";
import { AppState, BotStepStatus, EngagementPhase } from "../../store/definitions";
import { SessionReducer } from "../../store/session.reducer";
import { BotStepType, RouteToQueueBotStep } from "../../types/bots";

interface RouteToQueueStepProps {
    botStep: RouteToQueueBotStep;
}

const routeToQueueStepSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversation: state.chat.conversation,
        stateTaskAttributes: state.bot.taskAttributes,
        storeResponse: state.bot.storeResponse ?? false,
        storeResponseName: state.bot.storeResponseName,
        storeResponseAttributeIndex: state.bot.storeResponseAttributeIndex,
        conversationAttributes: state.bot.taskAttributes?.conversations as Record<string, unknown> | undefined
    })
);

export const RouteToQueueStep: FC<RouteToQueueStepProps> = ({ botStep }) => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { canHandleBotStep } = useCanHandleBotStep(botStep, {
        requiredStepType: BotStepType.RouteToQueue
    });
    const {
        conversation,
        stateTaskAttributes,
        storeResponse,
        storeResponseName,
        storeResponseAttributeIndex,
        conversationAttributes
    } = useSelector(routeToQueueStepSelector);

    const sendBotMessage = useCallback(
        async (message: string) => {
            dispatch(
                sendConversationMessage({
                    sentByBot: true,
                    conversation,
                    body: message,
                    storeResponse,
                    storeResponseAttributeIndex,
                    storeResponseName,
                    conversationAttributes
                })
            );
        },
        [
            conversation,
            sendConversationMessage,
            storeResponse,
            storeResponseAttributeIndex,
            storeResponseName,
            conversationAttributes
        ]
    );

    useEffect(() => {
        const handleBotStep = async () => {
            if (!canHandleBotStep) {
                return;
            }

            // Mark step as being processed, and hide message input
            dispatch(setActiveBotStepStatus(BotStepStatus.Processing));

            dispatch(setShowMessageInput(false));

            // adds some delay to the bot step
            const waitInMs = (botStep.waitInSeconds ?? DEFAULT_BOT_MESSAGE_DELAY_SECONDS) * 1000;
            await new Promise<void>((resolve) => setTimeout(resolve, waitInMs));

            // Display message with the twilio queue name and priority attached
            if (botStep.message) {
                sendBotMessage(botStep.message);
            }

            await sessionDataHandler.escalateConversation({
                Attributes: (conversation?.attributes as Record<string, unknown>) ?? {},
                conversationSid: conversation?.sid,
                twilioQueueName: botStep.queueId,
                taskPriority: botStep.priority,
                taskAttributes: { ...stateTaskAttributes, ...botStep.taskAttributes },
                ivrPath: botStep.ivrPath,
                pendingTaskExpiryDateTime: botStep.pendingTaskExpiryDateTime,
                pendingTaskExpiryScheduleId: botStep.pendingTaskExpiryScheduleId
            });

            dispatch(setIsEscalated(true));

            // Now we're being routed to a queue, allow use to input free text / upload media
            dispatch(setShowMessageInput(true));

            if (botStep.inQueueNextStepId) {
                dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                dispatch(setActiveBotStep(botStep.inQueueNextStepId));
            } else {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
            }
        };
        handleBotStep();
    }, [dispatch, sendBotMessage, canHandleBotStep, botStep, stateTaskAttributes, conversation]);

    return <></>;
};
