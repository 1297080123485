import { useMemo } from "react";
import { useSelector } from "react-redux";

import { createSelector } from "reselect";
import { AppState, BotStepStatus } from "../store/definitions";
import { BotStep, BotStepType } from "../types/bots";

interface UseCanHandleBotStepOptions {
    requiredStepType?: BotStepType;
}

const useCanHandleBotStepsSelector = createSelector(
    (state: AppState) => state,
    (state) => state.bot
);

export const useCanHandleBotStep = (botStep: BotStep, options?: UseCanHandleBotStepOptions) => {
    const botState = useSelector(useCanHandleBotStepsSelector);

    const canHandleBotStep = useMemo<boolean>(() => {
        if (!botStep) {
            console.log("[useCanHandleBotStep]", "failed because there is no bot step");
            return false;
        }

        if (options?.requiredStepType && botStep.type !== options.requiredStepType) {
            console.error(
                `[useCanHandleBotStep] Required bot step type of "${options.requiredStepType}" but received "${botStep.type}".`
            );
            throw new Error("Cannot handle bot step as the type is not what was expected");
        }

        if (botState?.status !== BotStepStatus.Unhandled) {
            console.log(
                `[useCanHandleBotStep] Active bot step is "${botState.status ? BotStepStatus[botState.status] : botState.status}" not "Unhandled", so ignoring`
            );
            return false;
        }

        return true;
    }, [botState?.status, botStep, options?.requiredStepType]);

    return {
        canHandleBotStep
    };
};
