import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Text } from "@twilio-paste/core/text";
import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";

import { createSelector } from "reselect";
import { notifications } from "../../notifications";
import { sessionDataHandler } from "../../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../../store/actions/genericActions";
import { initBotSession, initSession } from "../../store/actions/initActions";
import type { ChatReducer } from "../../store/chat.reducer";
import { AppState, EngagementPhase, PreEngagementData } from "../../store/definitions";
import type { SessionReducer } from "../../store/session.reducer";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { NotificationBar } from "../NotificationBar";
import { fieldStyles, formStyles, introStyles, titleStyles } from "../styles/PreEngagementFormPhase.styles";

const preEngagementFormPhaseSelector = createSelector(
    (state: AppState) => state,
    (state) => state.session.preEngagementData as PreEngagementData
);

export const PreEngagementFormPhase = () => {
    const preEngagementData = useSelector(preEngagementFormPhaseSelector) || {};
    const { name = "", email = "", query = "", phone = "" } = preEngagementData;
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const validateForm = (name: string, email: string, phone: string) => {
        const newErrors = {
            name: name.trim() === "" ? "This field is required" : "",
            email: email.trim() === "" ? "This field is required" : "",
            phone: phone.trim() === "" ? "This field is required" : ""
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error !== "");
    };

    const handleBlur = (field: string, value: string) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: value.trim() === "" ? "This field is required" : ""
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(name, email, phone)) return;
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const { token, conversationSid } = await sessionDataHandler.fetchAndStoreNewSession({
                email,
                phoneNumber: phone,
                twilioTaskQueueSid: process.env.REACT_APP_TASK_QUEUE_SID,
                twilioWorkflowSid: process.env.REACT_APP_WORKFLOW_SID,
                isBotConversation: process.env.REACT_APP_USE_BOTS === "true",
                studioFlowSid: process.env.REACT_APP_STUDIO_FLOW_SID,
                // FormData is passed into the Task's info tab
                formData: preEngagementData // Use all data from Redux store
            });
            if (process.env.REACT_APP_USE_BOTS === "true") {
                dispatch(initBotSession({ token, conversationSid }));
            } else {
                dispatch(initSession({ token, conversationSid }));
            }
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <>
            <Header logoUrl="https://media.licdn.com/dms/image/C4D0BAQF_kfIQ0fPG1w/company-logo_200_200/0/1631302775206?e=2147483647&v=beta&t=4AsLqcFKS338gPc5VkbPoVQng-8q6FzB5H9M049mWYY" />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...titleStyles} as="h3">
                    Hi there!
                </Text>
                <Text {...introStyles} as="p">
                    We&#39;re here to help. Please give us some info to get started.
                </Text>
                <Box display="flex" flexDirection="column">
                    <Box {...fieldStyles}>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            element="DEFAULT_INPUT"
                            type="text"
                            placeholder="Please enter your name"
                            name="name"
                            maxLength={200}
                            data-test="pre-engagement-chat-form-name-input"
                            value={name}
                            onChange={(e) => dispatch(updatePreEngagementData({ name: e.target.value }))}
                            onBlur={(e) => handleBlur("name", e.target.value)}
                            required
                        />
                        {errors.name && (
                            <Text color="colorTextError" as="symbol">
                                {errors.name}
                            </Text>
                        )}
                    </Box>
                    <Box {...fieldStyles}>
                        <Label htmlFor="email">Email address</Label>
                        <Input
                            element="DEFAULT_INPUT"
                            type="email"
                            placeholder="Please enter your email address"
                            name="email"
                            maxLength={200}
                            data-test="pre-engagement-chat-form-email-input"
                            value={email}
                            onChange={(e) => dispatch(updatePreEngagementData({ email: e.target.value }))}
                            onBlur={(e) => handleBlur("email", e.target.value)}
                            required
                        />
                        {errors.email && (
                            <Text color="colorTextError" as="symbol">
                                {errors.email}
                            </Text>
                        )}
                    </Box>
                    <Box {...fieldStyles}>
                        <Label htmlFor="phone">Phone number</Label>
                        <Input
                            element="DEFAULT_INPUT"
                            type="tel"
                            placeholder="Please enter your phone number"
                            name="phone"
                            maxLength={200}
                            data-test="pre-engagement-chat-form-phone-input"
                            value={phone}
                            onChange={(e) => dispatch(updatePreEngagementData({ phone: e.target.value }))}
                            onBlur={(e) => handleBlur("phone", e.target.value)}
                            required
                        />
                        {errors.phone && (
                            <Text color="colorTextError" as="symbol">
                                {errors.phone}
                            </Text>
                        )}
                    </Box>
                    <Box {...fieldStyles}>
                        <Label htmlFor="query">How can we help you?</Label>
                        <TextArea
                            element="DEFAULT_INPUT"
                            placeholder="Ask a question"
                            name="query"
                            maxLength={2000}
                            data-test="pre-engagement-chat-form-query-textarea"
                            value={query}
                            onChange={(e) => dispatch(updatePreEngagementData({ query: e.target.value }))}
                            onKeyPress={handleKeyPress}
                        />
                    </Box>
                    <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                        Start chat
                    </Button>
                </Box>
            </Box>
            <Footer />
        </>
    );
};
