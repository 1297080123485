import { Box } from "@twilio-paste/core/box";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { EntryPointLogo } from "./EntryPointLogo"; // Import the new component
import { containerStyles } from "./styles/EntryPoint.styles";

const entryPointSelector = createSelector(
    (state: AppState) => state,
    (state) => state.session.expanded
);

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector(entryPointSelector);

    if (isMobile && expanded) {
        return null;
    }

    return (
        <Box
            as="button"
            data-test="entry-point-button"
            onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
            {...containerStyles}
        >
            {expanded ? (
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" />
            ) : (
                <EntryPointLogo width="35" height="35" />
            )}
        </Box>
    );
};
