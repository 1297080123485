import { z } from "zod";

import { botStepSchema } from "./BotStep";

function stepsHaveUniqueIds(steps: z.infer<typeof botSteps>): boolean {
    const stepIds = new Set(steps.map((step) => step.stepId));

    return stepIds.size === steps.length;
}

const botSteps = z.array(botStepSchema);

export const botStepsSchema = botSteps.min(1).refine(stepsHaveUniqueIds, {
    message: "Step IDs should be unique"
});
