import { Conversation } from "@twilio/conversations";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { SyncClient } from "twilio-sync";
import { setIsRedirecting, setPending } from "../../../store/actions/genericActions";
import { BotConfig } from "../../../types/bots";
import { ChatReducer } from "../../chat.reducer";
import { SessionReducer } from "../../session.reducer";

export const initEventFlowsListener = async (
    token: string,
    conversation: Conversation,
    dispatch: ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>
) => {
    if (!token || !conversation) {
        return;
    }
    const syncClient = new SyncClient(token);

    const syncDocument = await syncClient.document({
        id: conversation?.sid + "_", // Underscore is required, as a sync document can't be created with the same SID as antoher resource.
        mode: "open_or_create",
        ttl: 604800 // A week.
    });

    if (!syncDocument) return;

    syncDocument.removeAllListeners();
    syncDocument.on("updated", (event: { data: { botConfig: BotConfig; conversationSid: string } }) => {
        dispatch(setIsRedirecting(true));
        dispatch(setPending(event.data.botConfig, event.data.conversationSid));
    });
};
