import { z } from "zod";

import { BotStepType } from "../BotStepType";

const stepFormFieldSchema = z.object({
    /**
     * The input type of the form field. Corresponds to the type passed to the actual HTML element, e.g. <input type="tel" />
     */
    inputType: z.enum(["text", "tel", "email"]),

    /**
     * The label displayed for the form field.
     */
    label: z.string(),

    /**
     * Whether the form field should be required.
     */
    required: z.boolean(),

    /**
     * If specified, this will be used on the rendered form element as the ID property.
     * If omitted, the label will be used as the ID instead.
     */
    id: z.string().optional(),

    /**
     * If specified, the value entered in the form field will be mapped to the specified profile field.
     */
    profileFieldName: z.string().optional()
});

function formFieldsHaveUniqueIds(formFields: z.infer<typeof stepFormFieldSchema>[]): boolean {
    const formFieldsWithIds = formFields.filter((formField) => formField.id !== null);
    const uniqueIDs = new Set();

    for (const formField of formFieldsWithIds) {
        uniqueIDs.add(formField.id);
    }

    return formFieldsWithIds.length === uniqueIDs.size;
}

export const inlineFormBotStepSchema = z.object({
    stepId: z.number(),
    message: z.string(),
    type: z.literal(BotStepType.Form),
    formFields: z
        .array(stepFormFieldSchema)
        .min(1)
        .refine(formFieldsHaveUniqueIds, { message: "Form fields should have unique IDs" }),
    submitButtonText: z.string(),
    nextStepId: z.number(),
    waitInSeconds: z.number().optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export type InlineFormBotStep = z.infer<typeof inlineFormBotStepSchema>;
