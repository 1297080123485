import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { InputBox } from "@twilio-paste/core/input-box";
import { TextArea } from "@twilio-paste/core/textarea";
import { SendIcon } from "@twilio-paste/icons/esm/SendIcon";
import throttle from "lodash.throttle";
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import packageJson from "../../package.json";
import { CHAR_LIMIT } from "../constants";
import { detachFiles, sendConversationMessage } from "../store/actions/genericActions";
import { ChatReducer } from "../store/chat.reducer";
import { AppState } from "../store/definitions";
import { SessionReducer } from "../store/session.reducer";
import { AttachFileButton } from "./AttachFileButton";
import { FilePreview } from "./FilePreview";
import {
    filePreviewContainerStyles,
    formStyles,
    innerInputStyles,
    messageOptionContainerStyles,
    textAreaContainerStyles
} from "./styles/MessageInput.styles";

type MessageInputProps = {
    onSendMessage?: (message: string) => void;
    disableMediaSending?: boolean;
};

const messageInputSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversation: state.chat.conversation,
        attachedFiles: state.chat.attachedFiles || [],
        fileAttachmentConfig: state.config.fileAttachment,
        flowName: state.bot.bot?.name,
        storeResponse: state.bot.storeResponse ?? false,
        storeResponseName: state.bot.storeResponseName,
        storeResponseAttributeIndex: state.bot.storeResponseAttributeIndex,
        conversationAttributes: state.bot.taskAttributes?.conversations as Record<string, unknown> | undefined
    })
);

export const MessageInput = ({ onSendMessage, disableMediaSending }: MessageInputProps) => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const [text, setText] = useState("");
    const [isSending, setIsSending] = useState(false);
    const {
        conversation,
        attachedFiles,
        fileAttachmentConfig,
        flowName,
        storeResponse,
        storeResponseName,
        storeResponseAttributeIndex,
        conversationAttributes
    } = useSelector(messageInputSelector);
    const oldAttachmentsLength = useRef((attachedFiles || []).length);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const attachmentsBoxRef = useRef<HTMLDivElement>(null);

    const throttleChange = useMemo(
        () =>
            throttle(() => {
                conversation?.typing();

                // in case the input was already focused, let's make sure to send the `read` status if the customer is typing
                if (conversation?.lastReadMessageIndex !== conversation?.lastMessage?.index) {
                    conversation?.setAllMessagesRead();
                }
            }, 500),
        [conversation]
    );

    const isSubmitDisabled = (!text.trim() && !attachedFiles?.length) || isSending;

    const generateInfoMessage = () => {
        return `Webchat Version: ${packageJson.version}
Flow Name: ${flowName}
URL: ${window.location.href}
User Agent: ${navigator.userAgent}
ConversationSid: ${conversation?.sid}`;
    };

    const send = useCallback(async () => {
        if (isSubmitDisabled) {
            return;
        }

        setIsSending(true);

        let messageToSend = text.trim();

        if (process.env.REACT_APP_USE_CLIENT_COMMANDS === "true" && messageToSend.match(/(\/|\\)info/)) {
            messageToSend = generateInfoMessage();
        }

        dispatch(
            sendConversationMessage({
                body: messageToSend,
                attachedFiles,
                conversation,
                storeResponse,
                storeResponseAttributeIndex,
                storeResponseName,
                conversationAttributes
            })
        );

        if (onSendMessage) onSendMessage(messageToSend);

        setText("");
        dispatch(detachFiles(attachedFiles));
        setIsSending(false);
        textAreaRef.current?.focus();
    }, [
        attachedFiles,
        conversation,
        dispatch,
        isSubmitDisabled,
        onSendMessage,
        sendConversationMessage,
        storeResponse,
        text
    ]);

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            (e.target as HTMLInputElement).form?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }
    };

    const onChange = (val: ChangeEvent<HTMLTextAreaElement>) => {
        setText(val.target.value);

        throttleChange();
    };

    const onFocus = () => {
        conversation?.setAllMessagesRead();
    };

    useEffect(() => {
        textAreaRef.current?.setAttribute("rows", "1");
        textAreaRef.current?.focus();
    }, [textAreaRef]);

    // Ensuring attached files are automatically scrolled to
    useEffect(() => {
        if (!attachmentsBoxRef.current) {
            return;
        }

        if (attachedFiles.length > oldAttachmentsLength.current) {
            (attachmentsBoxRef.current.lastChild as Element)?.scrollIntoView();
        }

        oldAttachmentsLength.current = attachedFiles.length;
    }, [attachedFiles]);

    return (
        <Box
            as="form"
            {...formStyles}
            onSubmit={(e) => {
                e.preventDefault();
                send();
            }}
        >
            <InputBox element="MESSAGE_INPUT_BOX" disabled={isSending}>
                <Box as="div" {...innerInputStyles}>
                    <Box {...textAreaContainerStyles}>
                        <TextArea
                            ref={textAreaRef}
                            data-test="message-input-textarea"
                            placeholder="Type your message"
                            value={text}
                            onChange={onChange}
                            onFocus={onFocus}
                            readOnly={isSending}
                            onKeyPress={onKeyPress}
                            maxLength={CHAR_LIMIT}
                            maxRows={1}
                        />
                    </Box>
                    {!disableMediaSending && (
                        <Box {...messageOptionContainerStyles}>
                            {fileAttachmentConfig?.enabled && <AttachFileButton textAreaRef={textAreaRef} />}
                        </Box>
                    )}
                    <Box {...messageOptionContainerStyles}>
                        <Button
                            data-test="message-send-button"
                            variant="primary_icon"
                            size="icon_small"
                            type="submit"
                            aria-disabled={isSubmitDisabled}
                        >
                            <SendIcon decorative={false} title="Send message" size="sizeIcon30" />
                        </Button>
                    </Box>
                </Box>
                {attachedFiles && (
                    <Box data-test="message-attachments" {...filePreviewContainerStyles} ref={attachmentsBoxRef}>
                        {attachedFiles.map((file, index) => (
                            <FilePreview
                                focusable={true}
                                key={index}
                                file={file}
                                isBubble={false}
                                disabled={isSending}
                            />
                        ))}
                    </Box>
                )}
            </InputBox>
        </Box>
    );
};
