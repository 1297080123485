import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";
import { notifications } from "../../notifications";
import { removeNotification } from "../../store/actions/genericActions";
import { AppState } from "../../store/definitions";
import { BotStepType } from "../../types/bots";
import { AttachFileDropArea } from "../AttachFileDropArea";
import { AddToIVRPathStep } from "../botSteps/AddToIVRPathStep";
import { CheckQueueCapacityStep } from "../botSteps/CheckQueueCapacityStep";
import { CheckTRAgentStep } from "../botSteps/CheckTRAgent";
import { CreateTaskStep } from "../botSteps/CreateTaskStep";
import { FreeTextResponseStep } from "../botSteps/FreeTextResponseStep";
import { MultipleChoiceStep } from "../botSteps/MultipleChoiceStep";
import { NoActionStep } from "../botSteps/NoActionStep";
import { RouteToQueueStep } from "../botSteps/RouteToQueueStep";
import { UpdateConversationLabelsStep } from "../botSteps/UpdateConversationLabelsStep";
import { UpdateTaskAttributesStep } from "../botSteps/UpdateTaskAttributesStep";
import { ConversationEnded } from "../ConversationEnded";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { MessageList } from "../MessageList";
import { NotificationBar } from "../NotificationBar";
import { Redirecting } from "../Redirecting";

const botCanvasPhaseSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversationState: state.chat.conversationState,
        bot: state.bot.bot,
        activeBotStepId: state.bot.activeBotStepId,
        isRedirecting: state.bot.isRedirecting
    })
);

export const BotCanvasPhase = () => {
    const dispatch = useDispatch();
    const { conversationState, bot, activeBotStepId, isRedirecting } = useSelector(botCanvasPhaseSelector);

    useEffect(() => {
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
    }, [dispatch]);

    const BotStepComponent = useMemo(() => {
        if (!activeBotStepId) {
            return <></>;
        }

        const botStep = bot?.botConfig.steps.find((step) => step.stepId === activeBotStepId);

        if (!botStep) {
            console.log("[BotCanvasPhase]", "Bot step not found");
            return <></>;
        }

        switch (botStep.type) {
            case BotStepType.MultipleChoice:
                return <MultipleChoiceStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.RouteToQueue:
                return <RouteToQueueStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.CreateTask:
                return <CreateTaskStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.NoAction:
                return <NoActionStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.FreeTextResponse:
                return <FreeTextResponseStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.UpdateTaskAttributes:
                return <UpdateTaskAttributesStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.UpdateConversationLabels:
                return <UpdateConversationLabelsStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.AddToIVRPath:
                return <AddToIVRPathStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.CheckQueueCapacity:
                return <CheckQueueCapacityStep botStep={botStep} key={botStep.stepId} />;

            case BotStepType.CheckLoggedInAgents:
                return <CheckTRAgentStep botStep={botStep} key={botStep.stepId} />;

            default:
                return <></>;
        }
    }, [bot, activeBotStepId]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;

    const BottomComponent = useMemo(() => {
        if (isRedirecting) {
            return <Redirecting />;
        } else if (conversationState === "active") {
            return BotStepComponent;
        } else {
            return <ConversationEnded />;
        }
    }, [isRedirecting, conversationState, BotStepComponent]);

    return (
        <Wrapper>
            <Header logoUrl="https://media.licdn.com/dms/image/C4D0BAQF_kfIQ0fPG1w/company-logo_200_200/0/1631302775206?e=2147483647&v=beta&t=4AsLqcFKS338gPc5VkbPoVQng-8q6FzB5H9M049mWYY" />
            <NotificationBar />
            <MessageList />
            {BottomComponent}
            <Footer />
        </Wrapper>
    );
};
