import { z } from "zod";

import { BotStepType } from "../BotStepType";
import { stepContentSchema } from "./MultipleChoiceBotStep";

export const callToActionTelOptionSchema = z.object({
    type: z.literal("PHONE_NUMBER"),
    title: z.string(),
    phone: z.string()
});

export const callToActionWebOptionSchema = z.object({
    type: z.literal("URL"),
    title: z.string(),
    url: z.string()
});

export const callToActionOptionSchema = callToActionTelOptionSchema.or(callToActionWebOptionSchema);
export const optionSchema = z.object({
    type: z.string(),
    operator: z.string(),
    value: z.string(),
    nextStepId: z.number(),
    previousNodeId: z.number().optional()
});

export const freePlainTextResponseBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.FreeTextResponse),
    subType: z.literal("plainText"),
    message: z.string(),
    nextStepId: z.number().optional(),
    profileFieldName: z.string().optional(),
    options: z.array(optionSchema).optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const freeTextCTAResponseBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.FreeTextResponse),
    subType: z.literal("callToAction"),
    callsToAction: z.array(callToActionOptionSchema).min(1),
    message: z.string(),
    profileFieldName: z.string().optional(),
    options: z.array(optionSchema).optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const freeTextMediaResponseBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.FreeTextResponse),
    subType: z.literal("media"),
    media: z.array(z.string()).min(1),
    profileFieldName: z.string().optional(),
    options: z.array(optionSchema).optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const cardFreeTextBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.FreeTextResponse),
    subType: z.literal("card"),
    content: z.array(stepContentSchema).min(1),
    ctaOptions: z.array(callToActionOptionSchema).min(1).optional(),
    options: z.array(optionSchema).optional(),
    waitInSeconds: z.number().optional(),
    variables: z.record(z.string(), z.string()).optional(),
    contentSid: z.string(),
    storeResponseName: z.string().optional()
});

export const freeTextResponseBotStepSchema = freePlainTextResponseBotStepSchema
    .or(freeTextCTAResponseBotStepSchema)
    .or(freeTextMediaResponseBotStepSchema)
    .or(cardFreeTextBotStepSchema);

export type CallToActionOption = z.infer<typeof callToActionOptionSchema>;
export type FreeTextResponseBotStep = z.infer<typeof freeTextResponseBotStepSchema>;
export type FreeTextOption = z.infer<typeof optionSchema>;
