import { z } from "zod";

import { botConfigSchema } from "./BotConfig";

export const createBotRequestPayloadSchema = z
    .object({
        id: z.string().optional(),
        siteUrl: z.string().optional(),
        name: z.string(),
        botId: z.string(),
        botConfig: botConfigSchema,
        botConfigOOH: botConfigSchema.optional()
    })
    .refine(
        (payload) => {
            /*
             * We want to check that if this is the default config, it has a (normally optional)
             * out of hours config defined. The OOH config is mandatory for the default config.
             */

            const isDefaultConfig = payload.botId === "DEFAULT_CONFIG";

            if (isDefaultConfig && payload.botConfigOOH === null) {
                return false;
            }

            return true;
        },
        { message: "The default config must have an out of hours config defined" }
    );

export type CreateBotRequestPayload = z.infer<typeof createBotRequestPayloadSchema>;
