import { z } from "zod";

import { BotStepType } from "../BotStepType";

export const createTaskBotStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.CreateTask),
    message: z.string(),
    queueId: z.string(),
    channelType: z.string(),
    taskChannel: z.string(),
    taskType: z.string(),
    ivrPath: z.string().optional(),
    taskAttributes: z.record(z.string(), z.string()).optional(),
    pendingTaskExpiryDateTime: z.string().optional(),
    waitInSeconds: z.number().optional(),
    nextStepId: z.number().optional()
});

export type CreateTaskBotStep = z.infer<typeof createTaskBotStepSchema>;
