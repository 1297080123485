export const EntryPointLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        id="entry-img"
        data-name="entry-img"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 90.8 90.2"
        {...props}
    >
        <defs>
            <style>
                {`
          #entry-img {
            fill: #FFFFFF;
            stroke-width: 0px;
            scale: 0.87;
            padding-bottom: 6px;
          }
        `}
            </style>
        </defs>
        <path
            className="entry-img"
            d="M57.8.4C38.7-1.4,21.2,13.4,21.5,32.8c-1.8,19.2,12.9,36.5,32.4,36.3h29.3c3.9,0,7-3.2,7-7v-29.3C90.2,14.9,75.7.4,57.8.4h0Z"
        />
        <path
            className="entry-img"
            d="M53.9,73.3c-25.8-.2-41.2-24.2-35.6-48.4C7.6,30.3.6,41.3.6,53.6v29.3c0,3.9,3.2,7,7,7h29.3c11.8,0,22.5-6.6,28.2-16.7h-11.3Z"
        />
    </svg>
);
