import { z } from "zod";

import { botConfigSchema } from "./BotConfig";

export const botSchema = z.object({
    id: z.string().optional(),
    siteUrl: z.string().optional(),
    name: z.string(),
    botId: z.string(),
    botConfig: botConfigSchema
});

export type Bot = z.infer<typeof botSchema>;
