import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";
import { JSONObject } from "@twilio/conversations";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import { endSession } from "../store/actions/endActions";
import { ChatReducer } from "../store/chat.reducer";
import { AppState, PreEngagementData } from "../store/definitions";
import { SessionReducer } from "../store/session.reducer";
import { containerStyles, textStyles, titleStyles } from "./styles/ConversationEnded.styles";
import type { Transcript } from "./Transcript";

export const conversationEndedSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        messages: state.chat.messages,
        users: state.chat.users,
        preEngagementData: (state.chat.conversation?.attributes as JSONObject)
            ?.pre_engagement_data as PreEngagementData,
        transcriptConfig: state.config.transcript,
        showTranscriptButton: state.bot.widgetConfig?.showTranscriptButton ?? false
    })
);

export const ConversationEnded = () => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { messages, users, preEngagementData, transcriptConfig, showTranscriptButton } =
        useSelector(conversationEndedSelector);

    const handleStartNewChat = () => {
        dispatch(endSession());
    };

    let TranscriptComponent: typeof Transcript | undefined = undefined;

    // This file and its related dependencies are only bundled if transcripts are enabled in .env file
    if (
        process.env.REACT_APP_DOWNLOAD_TRANSCRIPT_ENABLED === "true" ||
        process.env.EMAIL_TRANSCRIPT_ENABLED === "true"
    ) {
        ({ Transcript: TranscriptComponent } = require("./Transcript"));
    }

    return (
        <Box {...containerStyles}>
            <Text as="h3" {...titleStyles}>
                Thanks for chatting with us!
            </Text>
            {TranscriptComponent && showTranscriptButton ? (
                <TranscriptComponent
                    messages={messages}
                    preEngagementData={preEngagementData}
                    users={users}
                    transcriptConfig={transcriptConfig}
                />
            ) : (
                <Fragment />
            )}
            <Text as="p" {...textStyles}>
                If you have any more questions, feel free to reach out again.
            </Text>
            <Button variant="primary" data-test="start-new-chat-button" onClick={handleStartNewChat}>
                Start new chat
            </Button>
        </Box>
    );
};
