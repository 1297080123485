import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Reducer } from "redux";
import { thunk } from "redux-thunk";

import { BotReducer } from "./bot.reducer";
import { ChatReducer } from "./chat.reducer";
import { ConfigReducer } from "./config.reducer";
import { BotState } from "./definitions";
import { NotificationReducer } from "./notification.reducer";
import { SessionReducer } from "./session.reducer";

const typeWindow = window as unknown as { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose };

const composeEnhancers = typeWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    chat: ChatReducer,
    config: ConfigReducer,
    notifications: NotificationReducer,
    session: SessionReducer,
    bot: BotReducer as Reducer<BotState, AnyAction>
});

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
