import { z } from "zod";

import { BotStepType } from "../BotStepType";

export const updateTaskAttributesStepSchema = z.object({
    stepId: z.number(),
    type: z.literal(BotStepType.UpdateTaskAttributes),
    taskAttributes: z.record(z.string(), z.string()).optional(),
    waitInSeconds: z.number().optional(),
    nextStepId: z.number().optional()
});

export type UpdateTaskAttributesBotStep = z.infer<typeof updateTaskAttributesStepSchema>;
