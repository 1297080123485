import { z } from "zod";

import { BotStepType } from "../BotStepType";

export const routeToQueueBotStepSchema = z.object({
    stepId: z.number(),
    priority: z.number(),
    message: z.string().optional(),
    type: z.literal(BotStepType.RouteToQueue),
    queueId: z.string(),
    ivrPath: z.string().optional(),
    taskAttributes: z.record(z.string(), z.string()).optional(),
    pendingTaskExpiryDateTime: z.string().optional(),
    pendingTaskExpiryScheduleId: z.string().optional(),
    waitInSeconds: z.number().optional(),
    inQueueNextStepId: z.number().optional()
});

export type RouteToQueueBotStep = z.infer<typeof routeToQueueBotStepSchema>;
