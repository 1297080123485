import { Conversation, JSONValue } from "@twilio/conversations";
import log from "loglevel";
import { Dispatch } from "redux";

import Handlebars from "handlebars";
import { MESSAGES_LOAD_COUNT } from "../../constants";
import { BotConfig, CallToActionOption, ConversationLabelsUpdate } from "../../types/bots";
import { evaluateVariables } from "../../utils/evaluateVariables";
import { BotStepStatus, EngagementPhase, Notification, PreEngagementData } from "../definitions";
import {
    ACTION_ADD_MULTIPLE_MESSAGES,
    ACTION_ADD_NOTIFICATION,
    ACTION_ATTACH_FILES,
    ACTION_CHANGE_ENGAGEMENT_PHASE,
    ACTION_CHANGE_EXPANDED_STATUS,
    ACTION_DETACH_FILES,
    ACTION_END_SESSION,
    ACTION_INCREMENT_STORE_RESPONSE_ATTRIBUTE_INDEX,
    ACTION_REMOVE_NOTIFICATION,
    ACTION_RESET_BOT_DATA,
    ACTION_RESET_CHAT_STATE,
    ACTION_SET_ACTIVE_BOT_STEP,
    ACTION_SET_ACTIVE_BOT_STEP_STATUS,
    ACTION_SET_BOT_CONFIG,
    ACTION_SET_IS_ESCALATED,
    ACTION_SET_IS_REDIRECTING,
    ACTION_SET_PENDING_FLOW,
    ACTION_SET_SHOW_MESSAGE_INPUT,
    ACTION_SET_STORE_RESPONSE,
    ACTION_UPDATE_CONVERSATION_LABELS,
    ACTION_UPDATE_PRE_ENGAGEMENT_DATA,
    ACTION_UPDATE_TASK_ATTRIBUTES
} from "./actionTypes";

export function changeEngagementPhase({ phase }: { phase: EngagementPhase }) {
    return {
        type: ACTION_CHANGE_ENGAGEMENT_PHASE,
        payload: {
            currentPhase: phase
        }
    };
}

export function addNotification(notification: Notification) {
    return {
        type: ACTION_ADD_NOTIFICATION,
        payload: {
            notification
        }
    };
}

export function removeNotification(id: string) {
    return {
        type: ACTION_REMOVE_NOTIFICATION,
        payload: {
            id
        }
    };
}

export function getMoreMessages({ anchor, conversation }: { anchor: number; conversation: Conversation }) {
    return async (dispatch: Dispatch) =>
        dispatch({
            type: ACTION_ADD_MULTIPLE_MESSAGES,
            payload: {
                messages: (await conversation.getMessages(MESSAGES_LOAD_COUNT, anchor)).items
            }
        });
}

export function changeExpandedStatus({ expanded }: { expanded: boolean }) {
    return {
        type: ACTION_CHANGE_EXPANDED_STATUS,
        payload: {
            expanded
        }
    };
}

export function attachFiles(files: File[]) {
    return {
        type: ACTION_ATTACH_FILES,
        payload: {
            filesToAttach: files
        }
    };
}

export function detachFiles(files: File[]) {
    return {
        type: ACTION_DETACH_FILES,
        payload: {
            filesToDetach: files
        }
    };
}

export function updatePreEngagementData(data: Partial<PreEngagementData>) {
    return {
        type: ACTION_UPDATE_PRE_ENGAGEMENT_DATA,
        payload: {
            preEngagementData: data
        }
    };
}

export function endSession() {
    return {
        type: ACTION_END_SESSION
    };
}

export function resetBotData() {
    return {
        type: ACTION_RESET_BOT_DATA
    };
}

export function setActiveBotStepStatus(status: BotStepStatus) {
    return {
        type: ACTION_SET_ACTIVE_BOT_STEP_STATUS,
        payload: {
            status
        }
    };
}

export function setShowMessageInput(showMessageInput: boolean) {
    return {
        type: ACTION_SET_SHOW_MESSAGE_INPUT,
        payload: {
            showMessageInput
        }
    };
}

export function setStoreResponse(storeResponse: boolean, storeResponseName: string) {
    return {
        type: ACTION_SET_STORE_RESPONSE,
        payload: {
            storeResponse,
            storeResponseName
        }
    };
}

export function setActiveBotStep(botStepId: number) {
    return {
        type: ACTION_SET_ACTIVE_BOT_STEP,
        payload: {
            botStepId
        }
    };
}

export function setIsEscalated(isEscalated: boolean) {
    return {
        type: ACTION_SET_IS_ESCALATED,
        payload: {
            isEscalated
        }
    };
}

export function updateTaskAttributes(taskAttributes: Record<string, unknown>, conversationSid?: string) {
    return {
        type: ACTION_UPDATE_TASK_ATTRIBUTES,
        payload: {
            conversationSid,
            taskAttributes
        }
    };
}

export function updateConversationLabels(conversationLabels: ConversationLabelsUpdate[], conversationSid?: string) {
    return {
        type: ACTION_UPDATE_CONVERSATION_LABELS,
        payload: {
            conversationSid,
            conversationLabels
        }
    };
}

export function resetChatState() {
    return {
        type: ACTION_RESET_CHAT_STATE
    };
}

export function setBotConfig(botConfig: BotConfig) {
    return {
        type: ACTION_SET_BOT_CONFIG,
        payload: {
            botConfig
        }
    };
}

export function incrementStoreResponseAttributeIndex() {
    return {
        type: ACTION_INCREMENT_STORE_RESPONSE_ATTRIBUTE_INDEX
    };
}

export function setIsRedirecting(isRedirecting: boolean) {
    return {
        type: ACTION_SET_IS_REDIRECTING,
        payload: {
            isRedirecting
        }
    };
}

export function setPending(botConfig: BotConfig, conversationSid: string) {
    return {
        type: ACTION_SET_PENDING_FLOW,
        payload: {
            pendingBotConfig: botConfig,
            pendingConversationSid: conversationSid
        }
    };
}

export function clearPending() {
    return {
        type: ACTION_SET_PENDING_FLOW,
        payload: {
            pendingBotConfig: undefined,
            pendingConversationSid: undefined
        }
    };
}

export function sendConversationMessage({
    sentByBot = false,
    conversation,
    body,
    attachedFiles,
    header,
    footer,
    cta,
    variables,
    contentSid,
    storeResponse,
    storeResponseAttributeIndex,
    storeResponseName,
    conversationAttributes
}: {
    sentByBot?: boolean;
    conversation?: Conversation;
    body: string;
    attachedFiles?: File[];
    header?: string;
    footer?: string;
    cta?: CallToActionOption[];
    variables?: Record<string, string>;
    contentSid?: string;
    storeResponse: boolean;
    storeResponseAttributeIndex?: number;
    storeResponseName?: string;
    conversationAttributes?: Record<string, unknown>;
}) {
    return async (dispatch: Dispatch) => {
        if (!conversation) {
            log.error("Failed sending message: no conversation found");
            return;
        }

        let preparedMessage = conversation.prepareMessage();

        let messageAttributes: Record<string, unknown> = {
            sender: sentByBot ? "bot" : "user",
            header,
            footer,
            cta,
            contentSid
        };

        if (variables && Object.keys(variables).length > 0) {
            const evaluatedVariables = await evaluateVariables(variables, conversation.sid ?? undefined);
            const template = Handlebars.compile(body);
            body = template(evaluatedVariables);
            messageAttributes = { ...messageAttributes, contentVariables: evaluatedVariables };
        }

        if (storeResponse && !sentByBot) {
            const nextConversationAttribute = `conversation_attribute_${storeResponseAttributeIndex}`;
            const nextConversationLabel = `conversation_label_${storeResponseAttributeIndex}`;

            dispatch(
                updateTaskAttributes({
                    conversations: {
                        ...conversationAttributes,
                        [nextConversationAttribute]: body ?? "",
                        [nextConversationLabel]: storeResponseName
                    }
                })
            );

            dispatch(setStoreResponse(false, ""));
            dispatch(incrementStoreResponseAttributeIndex());
        }

        // add atribute to determine sender
        preparedMessage.setAttributes(messageAttributes as JSONValue);

        // add body
        preparedMessage = preparedMessage.setBody(body);

        // add attachments
        if (attachedFiles) {
            attachedFiles.forEach((file: File) => {
                const formData = new FormData();
                formData.append(file.name, file);
                preparedMessage.addMedia(formData);
            });
        }

        await preparedMessage.buildAndSend();
    };
}
