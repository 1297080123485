import { Button } from "@twilio-paste/core/button";
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from "@twilio-paste/core/modal";
import { useUID } from "@twilio-paste/core/uid-library";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { conversationEndedSelector } from "./ConversationEnded";
import { generateAndDownloadTranscript } from "./Transcript";

interface ConfirmationModalProps {
    triggerButtonText: string;
    modalHeading: string;
    modalBodyText1: string;
    modalBodyText2: string;
    confirmButtonText: string;
    cancelButtonText: string;
    downloadButtonText: string;
    alertHeading: string;
    alertBodyText: string;
    alertConfirmLabel: string;
    alertDismissLabel: string;
    onConfirm: () => void;
    destructive?: boolean;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    modalHeading,
    modalBodyText1,
    modalBodyText2,
    confirmButtonText,
    cancelButtonText,
    downloadButtonText,
    onConfirm
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalHeadingID = useUID();
    const dispatch = useDispatch();
    const { messages, users, preEngagementData, showTranscriptButton } = useSelector(conversationEndedSelector);

    const handleExternalDownload = async () => {
        await generateAndDownloadTranscript(messages, users, preEngagementData, dispatch);
    };

    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);

    return (
        <>
            <Button variant="secondary_icon" size="circle" onClick={handleModalOpen}>
                <CloseIcon decorative={false} title="Close the webchat" color="colorTextBrandInverse" />
            </Button>

            <Modal ariaLabelledby={modalHeadingID} isOpen={isModalOpen} onDismiss={handleModalClose} size="default">
                <ModalHeader>
                    <ModalHeading as="h3" id={modalHeadingID}>
                        {modalHeading}
                    </ModalHeading>
                </ModalHeader>
                <ModalBody>
                    <p>{modalBodyText1}</p>
                    <p>{modalBodyText2}</p>
                </ModalBody>
                <ModalFooter>
                    <ModalFooterActions>
                        {showTranscriptButton && (
                            <Button variant="primary" onClick={handleExternalDownload}>
                                {downloadButtonText}
                            </Button>
                        )}
                        <Button variant="secondary" onClick={handleModalClose}>
                            {cancelButtonText}
                        </Button>
                        <Button variant="destructive" onClick={onConfirm}>
                            {confirmButtonText}
                        </Button>
                    </ModalFooterActions>
                </ModalFooter>
            </Modal>
        </>
    );
};
