import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../store/definitions";
import { containerStyles, fullFooterStyles, miniFooterStyles } from "./styles/Footer.styles";

export const footerSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        fullFooter: state.bot.widgetConfig?.fullFooter,
        miniFooter: state.bot.widgetConfig?.miniFooter
    })
);

export const Footer = () => {
    const { fullFooter, miniFooter } = useSelector(footerSelector);

    return (
        (fullFooter || miniFooter) && (
            <Box as="footer" {...containerStyles}>
                <Box display="flex" justifyContent="left" alignContent="center">
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        {fullFooter && (
                            <Text as="h2" {...fullFooterStyles}>
                                {fullFooter}
                            </Text>
                        )}
                        {miniFooter && (
                            <Text as="p" {...miniFooterStyles} dangerouslySetInnerHTML={{ __html: miniFooter }} />
                        )}
                    </Box>
                </Box>
            </Box>
        )
    );
};
